import { api } from '../api';
import actions from './actions';
import { reducer } from './reducers';
import { store } from './store';

export type RootState = ReturnType<typeof reducer>;

export type RootAction = typeof actions;

export type AppDispatch = typeof store.dispatch;

export type Services = typeof api;

export type ExtraParamsThunkType<T> = {
  extra: { api: Services };
  rejectValue: T;
};

export enum LoadingStatus {
  idle = 'idle',
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export interface RequestErrors {
  message: string;
  statusCode: number;
  error: string;
}

export interface ResponseUnionType<T> {
  statusCode: number;
  message: string;
  data: T;
}
