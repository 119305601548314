import { Check, Close, Download, LockReset } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { api } from 'api';
import { Header } from 'components/organisms';
import { selectGoogleUsers, selectUsers } from 'features/users/selector';
import { GoogleUser, User } from 'features/users/types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { deleteUserAsync, getUsersAsync, resetPasswordAsync } from '../features/users/thunks';
import { useAppDispatch } from '../store/reducers';

const UsersPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const users = useSelector(selectUsers);
  const googleUsers = useSelector(selectGoogleUsers);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [currentUsers, setCurrentUsers] = useState<User[]>([]);
  const [currentGoogleUsers, setCurrentGoogleUsers] = useState<GoogleUser[]>([]);

  useEffect(() => {
    dispatch(getUsersAsync());
  }, [dispatch]);

  useEffect(() => {
    setCurrentUsers(users);
  }, [users]);

  useEffect(() => {
    setCurrentGoogleUsers(googleUsers);
  }, [googleUsers]);

  const ConvertToDate = (isoString: string) => {
    const date = new Date(isoString);
    return `${date.toDateString()} - ${date.toLocaleTimeString()}`;
  };

  const downloadChat = async (userId: string) => {
    try {
      const element = document.createElement('a');
      const data = await api.ChatApi.getChat({ userId });
      const file = new Blob([JSON.stringify(data.data, null, 4)], { type: 'application/json' });
      element.href = URL.createObjectURL(file);
      element.download = `${userId}.json`;
      element.click();
    } catch (e) {
      setShowErrorAlert(true);
      setAlertMessage('Downloading chat failed');
    } finally {
      setShowDeleteModal(false);
      setSelectedUser(undefined);
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      await dispatch(deleteUserAsync(userId));
      setShowSuccessAlert(true);
      setAlertMessage('User successfully deleted.');
    } catch (e) {
      setShowErrorAlert(true);
      setAlertMessage('Deleting user failed');
    } finally {
      setShowDeleteModal(false);
      setSelectedUser(undefined);
    }

    await dispatch(getUsersAsync());
  };

  const resetPasswordOfUser = async (email: string) => {
    try {
      await dispatch(resetPasswordAsync({ email }));
      setShowSuccessAlert(true);
      setAlertMessage('Password successfully reset.');
    } catch (error) {
      setShowErrorAlert(true);
      setAlertMessage('Resetting password failed');
    } finally {
      setShowResetPasswordModal(false);
      setSelectedUser(undefined);
    }

    await dispatch(getUsersAsync());
  };

  return (
    <Container>
      <Header />
      <Typography variant="h5" gutterBottom>
        Users
      </Typography>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="users table">
        <TableHead>
          <TableRow>
            <TableCell>Number</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>User ID</TableCell>
            <TableCell>Verified</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Modified</TableCell>
            <TableCell>
              Last Login <TableSortLabel active onClick={() => setCurrentUsers(currentUsers.slice().reverse())} />
            </TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentUsers.map((user, index) => (
            <TableRow key={`user_${index}`}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.userId}</TableCell>
              <TableCell>{user.isVerified ? <Check /> : <Close />}</TableCell>
              <TableCell>{ConvertToDate(user.createdAt)}</TableCell>
              <TableCell>{ConvertToDate(user.modifiedAt)}</TableCell>
              <TableCell>{ConvertToDate(user.lastLogin)}</TableCell>
              <TableCell>
                <DeleteIcon
                  onClick={() => {
                    setShowDeleteModal(true);
                    setSelectedUser(user);
                  }}
                />
                <Download
                  onClick={() => {
                    downloadChat(user.userId);
                  }}
                />
                <LockReset
                  onClick={() => {
                    setShowResetPasswordModal(true);
                    setSelectedUser(user);
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        Google Users
      </Typography>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="google users table">
        <TableHead>
          <TableRow>
            <TableCell>Number</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Google ID</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Modified</TableCell>
            <TableCell>
              Last Login
              <TableSortLabel active onClick={() => setCurrentGoogleUsers(currentGoogleUsers.slice().reverse())} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentGoogleUsers.map((googleUser, index) => (
            <TableRow key={`google_user_${index}`}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{googleUser.name}</TableCell>
              <TableCell>{googleUser.email}</TableCell>
              <TableCell>{googleUser.googleId}</TableCell>
              <TableCell>{ConvertToDate(googleUser.createdAt)}</TableCell>
              <TableCell>{ConvertToDate(googleUser.modifiedAt)}</TableCell>
              <TableCell>{ConvertToDate(googleUser.lastLogin)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <ModalContainer>
          <Typography variant="h6">Delete user?</Typography>
          <Typography sx={{ mt: 2 }}>Are you sure you want to delete {selectedUser?.name}?</Typography>
          <ActionButtons>
            <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
            <Button onClick={() => selectedUser && deleteUser(selectedUser.userId)}>Confirm</Button>
          </ActionButtons>
        </ModalContainer>
      </Modal>

      <Modal open={showResetPasswordModal} onClose={() => setShowResetPasswordModal(false)}>
        <ModalContainer>
          <Typography variant="h6">Reset password?</Typography>
          <Typography sx={{ mt: 2 }}>Are you sure you want to reset the password for {selectedUser?.name}?</Typography>
          <ActionButtons>
            <Button onClick={() => setShowResetPasswordModal(false)}>Cancel</Button>
            <Button onClick={() => selectedUser && resetPasswordOfUser(selectedUser.email)}>Confirm</Button>
          </ActionButtons>
        </ModalContainer>
      </Modal>

      {showErrorAlert && (
        <CustomAlert severity="error" onClose={() => setShowErrorAlert(false)}>
          {alertMessage}
        </CustomAlert>
      )}
      {showSuccessAlert && (
        <CustomAlert severity="success" onClose={() => setShowSuccessAlert(false)}>
          {alertMessage}
        </CustomAlert>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 20px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const CustomAlert = styled(Alert)`
  position: fixed;
  top: 0;
  z-index: 10;
  width: 97%;
`;

export default UsersPage;
