import { AxiosResponse } from 'axios';

import { http } from '../services/http';

export interface SignInDto {
  email: string;
  password: string;
}

export enum Role {
  ADMIN = 'admin',
  USER = 'user',
}

export interface SignInResDto {
  data: {
    userId: string;
    email: string;
    name: string;
    role: Role;
    accessToken: string;
    expiresIn: string;
  };
  message: string;
  statusCode: number;
}

export const AuthApi = {
  signIn: async (data: SignInDto): Promise<AxiosResponse<SignInResDto>> => {
    return http.post('auth/login', data);
  },
};
