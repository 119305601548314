import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import { api } from '../api';
import { authMiddleware } from './middlewares';
import extractedReducer from './reducers';

const reduxLogger = createLogger({ collapsed: true });

const store = configureStore({
  reducer: extractedReducer,
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: {
        extraArgument: { api },
      },
      serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
    })
      .concat(authMiddleware)
      .concat(reduxLogger);
  },
});

const persistor = persistStore(store);

export { persistor, store };
