import { AxiosResponse } from 'axios';

import { GoogleUser, User } from '../features/users/types';
import { http } from '../services/http';
import { ResponseUnionType } from '../store/types';

export interface ResetPasswordDto {
  email: string;
}

export interface ResetPasswordResDto {
  data: User;
  message: string;
  statusCode: number;
}

export interface GetUsersResDto {
  users: User[];
  googleUsers: GoogleUser[];
}

export const UsersApi = {
  getUsers: async (): Promise<AxiosResponse<GetUsersResDto>> => {
    return http.get('users');
  },
  deleteUser: async (userId: string): Promise<AxiosResponse<ResponseUnionType<void>>> => {
    return http.delete(`/users/${userId}`);
  },
  resetPassword: async (data: ResetPasswordDto): Promise<AxiosResponse<ResetPasswordResDto>> => {
    return http.post('users/reset-password', data);
  },
};
