import { createSelector } from 'reselect';

import { RootState } from '../../store/types';
import { UsersStore } from './types';

export const selectUsersState = (state: RootState): UsersStore => state.users;

export const selectUsers = createSelector(selectUsersState, (users) => users.users);
export const selectGoogleUsers = createSelector(selectUsersState, (users) => users.googleUsers);
export const selectUsersLoadingStatus = createSelector(selectUsersState, (users) => users.getUsersLoadingStatus);
