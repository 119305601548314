import { Button, TextField } from '@mui/material';
import { signInAsync } from 'features/auth/thunks';
import React, { useState } from 'react';
import { useAppDispatch } from 'store/reducers';

const AuthorizationPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const signIn = async () => {
    await dispatch(signInAsync({ email, password }));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '30px' }}>
      <TextField label="Email" type="username" value={email} onChange={(e) => setEmail(e.target.value)} />

      <TextField label="Password" type="password" onChange={(e) => setPassword(e.target.value)} />

      <Button type="submit" onClick={signIn}>
        Submit
      </Button>
    </div>
  );
};

export default AuthorizationPage;
