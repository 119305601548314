import { AxiosResponse } from 'axios';

import { Chat } from '../features/users/types';
import { gptHttp } from '../services/http';

export interface GetChatDto {
  userId: string;
}

export interface GetChatResDto {
  chat_history: Array<Chat>;
  count: number;
  statusCode: number;
}

export const ChatApi = {
  getChat: async (data: GetChatDto): Promise<AxiosResponse<GetChatResDto>> => {
    console.log('Test api');
    return gptHttp.post('chats', data);
  },
};
