import { Text } from 'components/atoms/Text';
import { actions as authAction } from 'features/auth';
import LogoutIcon from 'icons/Logout';
import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';

const HeaderComponent: React.FC = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  const signOutHandler = () => {
    dispatch(authAction.signOut());
  };

  return (
    <Maindiv ref={ref}>
      <MenuWrapper>
        <Text type="body1normal" textColor="text" weight="bold">
          Darwin Intelligence Admin Portal
        </Text>
        <RightSection>
          {!location.pathname.includes('auth') && (
            <Logout onClick={signOutHandler}>
              <LogoutIcon />
              <Text type="body1normal" textColor="text" weight="bold">
                Logout
              </Text>
            </Logout>
          )}
        </RightSection>
      </MenuWrapper>
    </Maindiv>
  );
};

const Maindiv = styled.div`
  width: 100%;
  position: sticky;
  z-index: 101;
  background-color: ${(props) => props.theme.colors.darkGrey};
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.colors.shadeGrey};
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
`;

const RightSection = styled.div`
  display: flex;
  gap: 10px;
`;

const Logout = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.purple};
  }
`;

export default HeaderComponent;
