import React from 'react';
import styled, { useTheme } from 'styled-components';

interface TextProps {
  type: string;
  textColor?: string;
  hoverColor?: string;
  focusedColor?: string;
  pressedColor?: string;
  weight?: 'bold' | 'medium' | 'regular';
  children: React.ReactNode;
  padding?: string;
}

const Text: React.FC<TextProps> = ({
  type,
  textColor = 'text',
  hoverColor,
  focusedColor,
  pressedColor,
  weight = 'regular',
  children,
  padding = '0px',
}) => {
  const theme = useTheme();
  const isValidCombination = type in theme.fonts;
  const finalCombination = isValidCombination ? type : 'body1normal';

  // Function to detect list items based on a pattern
  const isListItem = (text: string) => /^(\*|[-]|[0-9]+\.)\s/.test(text);

  // Split children into lines if it's a string, or leave as-is if not
  const content =
    typeof children === 'string'
      ? children.split('\n').map((line, index) =>
          isListItem(line) ? (
            <li key={index}>{line.replace(/^(\*|[-]|[0-9]+\.)\s/, '')}</li> // Only format as list if it's a valid list item
          ) : (
            <div key={index}>{line}</div> // Otherwise, render as normal text
          ),
        )
      : children;

  // Check if there's any list item, render a <ul> only if necessary
  const isAnyListItem = typeof children === 'string' && children.split('\n').some(isListItem);

  return (
    <TextWrapper
      as={isAnyListItem ? 'p' : ''} // Render as <ul> if any list item is found
      type={finalCombination}
      color={textColor}
      hoverColor={hoverColor}
      focusedColor={focusedColor}
      pressedColor={pressedColor}
      weight={weight}
      padding={padding}>
      {content}
    </TextWrapper>
  );
};

const TextWrapper = styled.div<{
  type: string;
  color: string;
  hoverColor?: string;
  focusedColor?: string;
  pressedColor?: string;
  weight: 'bold' | 'medium' | 'regular';
  padding: string;
}>`
  padding: ${(props) => props.padding};
  font-size: ${(props) => props.theme.fonts[props.type]?.fontSize || props.theme.fonts['body1normal'].fontSize};
  line-height: ${(props) => props.theme.fonts[props.type]?.lineHeight || props.theme.fonts['body1normal'].lineHeight};
  font-weight: ${(props) => {
    switch (props.weight) {
      case 'bold':
        return 700;
      case 'medium':
        return 500;
      case 'regular':
        return 400;
      default:
        return props.theme.fonts[props.type]?.fontWeight || 400;
    }
  }};
  letter-spacing: ${(props) =>
    props.theme.fonts[props.type]?.letterSpacing || props.theme.fonts['body1normal'].letterSpacing};

  color: ${(props) => props.theme.colors[props.color] || props.theme.colors.text};

  ${(props) =>
    props.color === 'gradient'
      ? `
      background: ${props.theme.colors.gradient};
      -webkit-text-fill-color: transparent;
      background-clip: text;
    `
      : ''}

  &:hover {
    ${(props) =>
      props.hoverColor && props.color !== 'gradient'
        ? `
      color: ${props.theme.colors[props.hoverColor]};
    `
        : ''}
  }

  &:focus {
    ${(props) =>
      props.focusedColor && props.color !== 'gradient'
        ? `
      color: ${props.theme.colors[props.focusedColor]};
    `
        : ''}
  }

  &:active {
    ${(props) =>
      props.pressedColor && props.color !== 'gradient'
        ? `
      color: ${props.theme.colors[props.pressedColor]};
    `
        : ''}
  }

  ul {
    list-style-type: disc;
    padding-left: 1.5em;
  }
`;

export default Text;
