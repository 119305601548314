import './App.css';

import Theme from 'Theme';

import { AppRouting } from './routes';

function App() {
  return (
    <div className="App">
      <Theme>
        <AppRouting />
      </Theme>
    </div>
  );
}

export default App;
