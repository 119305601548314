import { signInAsync } from 'features/auth/thunks';
import { AnyAction, Dispatch } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { gptHttp, http } from 'services/http';

import actions from './actions';

export const authMiddleware =
  () =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction): AnyAction => {
    if (action.type === actions.authActions.signIn.type || action.type === signInAsync.fulfilled.type) {
      http.setAuthorizationHeader(action.payload.data.accessToken);
      gptHttp.setAuthorizationHeader(action.payload.data.accessToken);
    }

    if (action.type === REHYDRATE) {
      action?.payload?.accessToken && http.setAuthorizationHeader(action?.payload?.accessToken);
    }

    return next(action);
  };
