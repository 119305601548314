import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'store/types';

import { signInAsync } from './thunks';
import { AuthStore } from './types';

const initialState: AuthStore = {
  userId: undefined,
  email: undefined,
  name: undefined,
  accessToken: undefined,
  isAuthorized: false,
  role: undefined,
  loadingStatus: LoadingStatus.idle,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.accessToken = action.payload;
      state.isAuthorized = true;
    },
    signOut: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(signInAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(signInAsync.fulfilled, (state, { payload }) => {
      return { ...state, ...payload.data, loadingStatus: LoadingStatus.idle, isAuthorized: true };
    });
    builder.addCase(signInAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
  },
});

export const actions = {
  ...authSlice.actions,
};
export const { reducer } = authSlice;
