import { createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from '../../store/types';
import { getUsersAsync } from './thunks';
import { UsersStore } from './types';

const initialState: UsersStore = {
  users: [],
  googleUsers: [],
  getUsersLoadingStatus: LoadingStatus.idle,
  error: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsersAsync.pending, (state) => {
      if (state.getUsersLoadingStatus === LoadingStatus.idle) {
        state.getUsersLoadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getUsersAsync.fulfilled, (state, { payload }) => {
      return {
        ...state,
        users: payload.users,
        googleUsers: payload.googleUsers,
        getDiarizedLoadingStatus: LoadingStatus.idle,
      };
    });
    builder.addCase(getUsersAsync.rejected, (state) => {
      state.getUsersLoadingStatus = LoadingStatus.idle;
    });
  },
});

export const actions = {
  ...usersSlice.actions,
};
export const { reducer } = usersSlice;
