import { AuthorizationPage } from 'pages';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/sign-in',
    element: <AuthorizationPage />,
    index: true,
  },
  { path: '*', element: <Navigate to={'/sign-in'} /> },
]);

const UnauthorizedRouting = () => {
  return <RouterProvider router={router} />;
};

export default UnauthorizedRouting;
