import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as AuthReducer } from '../features/auth';
import { reducer as UsersReducer } from '../features/users';
import { AppDispatch } from './types';

const authPersistConfig = {
  storage,
  key: 'auth',
  whitelist: ['accessToken', 'isAuthorized', 'role'],
};

export const reducer = combineReducers({
  users: UsersReducer,
  auth: persistReducer(authPersistConfig, AuthReducer),
});

export type State = ReturnType<typeof reducer>;

const rootReducer = (state: State, action: Action): State => {
  const nextState = state as State | undefined;

  return reducer(nextState, action);
};

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export default rootReducer as typeof reducer;
