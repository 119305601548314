import { selectIsAdmin, selectIsAuthorized } from 'features/auth/selectors';
import { useSelector } from 'react-redux';

import AuthorizedRouting from './AuthorizedRouting';
import UnauthorizedRouting from './UnauthorizedRouting';

const AppRouting = () => {
  const isAuthorized = useSelector(selectIsAuthorized);
  const isAdmin = useSelector(selectIsAdmin);

  if (isAuthorized && isAdmin) {
    return <AuthorizedRouting />;
  }
  return <UnauthorizedRouting />;
};

export default AppRouting;
