import UsersPage from 'pages/Users';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/users',
    element: <UsersPage />,
  },
  { path: '*', element: <Navigate to={'/users'} /> },
]);

const AuthorizedRouting = () => {
  return <RouterProvider router={router} />;
};

export default AuthorizedRouting;
