import { createAsyncThunk } from '@reduxjs/toolkit';
import { SignInDto, SignInResDto } from 'api/auth.api';
import { ExtraParamsThunkType, RequestErrors } from 'store/types';

export const signInAsync = createAsyncThunk<SignInResDto, SignInDto, ExtraParamsThunkType<RequestErrors>>(
  'auth/login',
  async (userData: SignInDto, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.AuthApi.signIn(userData);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);
